.admin-config h2 {
  margin-bottom: 40px; }

.admin-config .btn-toolbar {
  width: 30%;
  justify-content: space-between; }
  .admin-config .btn-toolbar .input-group-prepend .input-group-text {
    border-right: none;
    background-color: white;
    padding: 0.375rem 0 0.375rem 0.75rem; }
  .admin-config .btn-toolbar .input-group-prepend + input {
    border-left: none; }
    .admin-config .btn-toolbar .input-group-prepend + input:focus {
      box-shadow: none;
      border-color: #ced4da; }

.admin-config .card {
  display: none;
  width: 30%;
  margin-left: 30px;
  box-shadow: 0 0 2px gray;
  height: 100%;
  min-height: 220px; }
  .admin-config .card.active {
    display: flex; }
  .admin-config .card .card-header {
    padding: 15px 25px;
    background-color: #FFFFFF;
    border-bottom: none; }
    .admin-config .card .card-header .btn-toolbar {
      width: 100%;
      justify-content: flex-end; }
  .admin-config .card .card-body {
    padding: 15px 25px; }
    .admin-config .card .card-body .card-text {
      margin-bottom: 10px; }
    .admin-config .card .card-body h6 {
      padding: 5px 0 5px 10px;
      letter-spacing: 0.5px;
      cursor: pointer;
      font-weight: 600; }
      .admin-config .card .card-body h6 .fa-angle-down {
        display: none; }
      .admin-config .card .card-body h6[aria-expanded="true"] .fa-angle-right {
        display: none; }
      .admin-config .card .card-body h6[aria-expanded="true"] .fa-angle-down {
        display: initial; }
      .admin-config .card .card-body h6[aria-expanded="false"]:hover {
        font-weight: 700; }
    .admin-config .card .card-body .collapse div {
      min-width: 20%;
      font-weight: 600; }
      .admin-config .card .card-body .collapse div.text-right {
        min-width: 60%;
        font-weight: 400; }
    .admin-config .card .card-body .collapsing {
      transition: none;
      display: none; }
  .admin-config .card .card-footer {
    background-color: white;
    border-top: none;
    text-align: right; }
    .admin-config .card .card-footer button {
      min-width: 75px;
      margin-left: 5px; }

[data-modal-dialog="admin-delete"] .modal-content,
[data-modal-dialog="admin-generate"] .modal-content {
  padding: 0 15px 15px; }
  [data-modal-dialog="admin-delete"] .modal-content .modal-body,
  [data-modal-dialog="admin-generate"] .modal-content .modal-body {
    padding-top: 25px;
    text-align: center; }
    [data-modal-dialog="admin-delete"] .modal-content .modal-body h3,
    [data-modal-dialog="admin-generate"] .modal-content .modal-body h3 {
      margin-top: 20px; }
    [data-modal-dialog="admin-delete"] .modal-content .modal-body p,
    [data-modal-dialog="admin-generate"] .modal-content .modal-body p {
      margin-bottom: 0; }
      [data-modal-dialog="admin-delete"] .modal-content .modal-body p span,
      [data-modal-dialog="admin-generate"] .modal-content .modal-body p span {
        font-weight: 700; }
  [data-modal-dialog="admin-delete"] .modal-content .modal-footer,
  [data-modal-dialog="admin-generate"] .modal-content .modal-footer {
    padding: 5px 10px;
    border-top: none; }
  [data-modal-dialog="admin-delete"] .modal-content .divider,
  [data-modal-dialog="admin-generate"] .modal-content .divider {
    width: 30%;
    margin: 0 auto;
    border-bottom: 2px solid black;
    position: relative; }
    [data-modal-dialog="admin-delete"] .modal-content .divider span,
    [data-modal-dialog="admin-generate"] .modal-content .divider span {
      position: absolute;
      transform: translate(-50%, -50%);
      background: white;
      padding: 0 7px; }

.config-editor[multi-column] .card-body .entity-name {
  display: flex;
  margin-bottom: 20px; }
  .config-editor[multi-column] .card-body .entity-name div:first-child {
    width: 60%; }

.config-editor[multi-column] .card-body .column-names {
  display: flex;
  margin-bottom: 5px; }
  .config-editor[multi-column] .card-body .column-names div {
    padding: 0;
    width: 20%;
    font-size: 14px;
    font-weight: 600; }
    .config-editor[multi-column] .card-body .column-names div:first-child {
      margin-left: 60%; }
  .config-editor[multi-column] .card-body .column-names ~ div {
    margin-bottom: 5px; }

.config-editor:not([multi-column]) .collapse > div {
  margin-bottom: 5px; }

.config-editor textarea {
  min-height: 90px;
  resize: none; }

.config-editor label {
  margin-bottom: 0;
  vertical-align: middle; }

.config-list {
  width: 30%;
  height: 100%;
  background-color: #fefefe;
  box-shadow: 0 0 2px gray;
  border: 1px solid #ccc;
  border-radius: 3px; }
  .config-list ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0; }
    .config-list ul li {
      padding: 10px 20px;
      border-bottom: 1px solid rgba(153, 153, 153, 0.5); }
      .config-list ul li.selected {
        font-weight: bold; }
      .config-list ul li.blank-list {
        text-align: center;
        cursor: initial; }
      .config-list ul li:last-of-type {
        border-bottom: none; }
      .config-list ul li:hover:not(.blank-list) {
        background-color: #fafafa;
        cursor: pointer; }

.config-viewer[multi-column] .column-names {
  display: flex;
  margin-bottom: 5px; }
  .config-viewer[multi-column] .column-names div {
    padding: 0;
    width: 20%;
    font-size: 14px;
    font-weight: 600; }
    .config-viewer[multi-column] .column-names div:first-child {
      margin-left: 60%; }

.config-viewer h4 {
  margin-bottom: 25px;
  font-weight: 600;
  letter-spacing: 0.5px; }

#rotating_machinery_settings {
  padding-bottom: 50px; }
  #rotating_machinery_settings h1 {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Titillium Web', sans-serif;
    letter-spacing: 2px;
    margin-bottom: 30px; }
  #rotating_machinery_settings .nav-pills {
    font-size: 18px;
    font-weight: 700;
    font-family: 'Titillium Web', sans-serif;
    letter-spacing: 1px;
    width: 60vw; }
    #rotating_machinery_settings .nav-pills li {
      width: 20vw;
      text-align: center; }
      #rotating_machinery_settings .nav-pills li a {
        display: inline;
        color: #808080; }
        #rotating_machinery_settings .nav-pills li a.active {
          background-color: transparent;
          border-bottom: 2px solid #326EB0;
          border-radius: 0;
          color: #666; }
  #rotating_machinery_settings .tab-content {
    padding-top: 50px; }
    #rotating_machinery_settings .tab-content [data-role="viewer"] .card-text {
      margin-bottom: 0.75rem; }
      #rotating_machinery_settings .tab-content [data-role="viewer"] .card-text h6 {
        margin-top: 5px; }
      #rotating_machinery_settings .tab-content [data-role="viewer"] .card-text span {
        display: inline-block; }
        #rotating_machinery_settings .tab-content [data-role="viewer"] .card-text span:first-of-type {
          width: 60%;
          text-align: right; }
        #rotating_machinery_settings .tab-content [data-role="viewer"] .card-text span:last-of-type {
          padding-left: 10px;
          font-weight: 600; }
    #rotating_machinery_settings .tab-content [data-role="editor"] .form-group {
      margin-bottom: 3px; }
      #rotating_machinery_settings .tab-content [data-role="editor"] .form-group input {
        padding: 2px 6px; }
    #rotating_machinery_settings .tab-content [data-type="configurations"] [data-role="viewer"] .card-header,
    #rotating_machinery_settings .tab-content [data-type="configurations"] [data-role="editor"] .card-header {
      padding-bottom: 0; }
      #rotating_machinery_settings .tab-content [data-type="configurations"] [data-role="viewer"] .card-header .card-title,
      #rotating_machinery_settings .tab-content [data-type="configurations"] [data-role="editor"] .card-header .card-title {
        margin-bottom: 0; }
    #rotating_machinery_settings .tab-content [data-type="configurations"] [data-role="viewer"] .card-body > .row,
    #rotating_machinery_settings .tab-content [data-type="configurations"] [data-role="editor"] .card-body > .row {
      font-weight: 600; }

[data-modal-dialog="admin-fft-select"] {
  position: relative; }
  [data-modal-dialog="admin-fft-select"] .modal-dialog {
    max-width: 350px; }
    [data-modal-dialog="admin-fft-select"] .modal-dialog .modal-body {
      padding: 45px 35px 30px; }
    [data-modal-dialog="admin-fft-select"] .modal-dialog select:focus {
      border: 1px solid #ced4da;
      box-shadow: none; }
    [data-modal-dialog="admin-fft-select"] .modal-dialog button {
      margin-top: 30px; }
  [data-modal-dialog="admin-fft-select"] .mfp-close {
    left: calc(50% + 140px);
    top: 50px; }

#super_admin_portal ul {
  list-style: none; }
  #super_admin_portal ul .card {
    cursor: pointer; }
    #super_admin_portal ul .card:hover {
      background-color: rgba(200, 200, 200, 0.1); }
      #super_admin_portal ul .card:hover h6,
      #super_admin_portal ul .card:hover .fa-user-tie {
        color: rgba(0, 0, 0, 0.7);
        font-weight: bolder; }
    #super_admin_portal ul .card svg,
    #super_admin_portal ul .card h6 {
      color: rgba(128, 128, 128, 0.5); }
    #super_admin_portal ul .card h6 {
      line-height: 2rem; }
    #super_admin_portal ul .card[data-id]:hover h6,
    #super_admin_portal ul .card[data-id]:hover .fa-user-tie {
      color: rgba(0, 0, 0, 0.7);
      font-weight: bolder; }
    #super_admin_portal ul .card[data-id] h6,
    #super_admin_portal ul .card[data-id] .fa-user-tie {
      color: rgba(100, 100, 100, 0.9); }
    #super_admin_portal ul .card .fa-plus-square,
    #super_admin_portal ul .card .fa-trash-alt,
    #super_admin_portal ul .card .fa-edit {
      cursor: pointer; }
      #super_admin_portal ul .card .fa-plus-square:hover,
      #super_admin_portal ul .card .fa-trash-alt:hover,
      #super_admin_portal ul .card .fa-edit:hover {
        color: rgba(0, 0, 0, 0.8); }

[data-modal-dialog="organization-editor"] {
  width: 650px; }
  [data-modal-dialog="organization-editor"] .custom-control-label {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 500;
    color: rgba(33, 33, 33, 0.8); }
  [data-modal-dialog="organization-editor"] .admin-password,
  [data-modal-dialog="organization-editor"] .admin-email {
    display: none; }
  [data-modal-dialog="organization-editor"] button[type="button"] {
    display: none; }
  [data-modal-dialog="organization-editor"].saving button {
    display: none; }
    [data-modal-dialog="organization-editor"].saving button[type="button"] {
      display: inline-block; }

/*@import 'rotating-machinery/page';
@import 'rotating-machinery/results';
@import 'rotating-machinery/selectors-section';
@import 'rotating-machinery/dialogs';*/
#generator_output_cms .page-header {
  color: white; }

#generator_output_cms .page-container {
  padding: 10px 30px; }

#generator_output_cms #toolbar button {
  background-color: #333; }

#generator_output_cms #filters {
  width: 20%;
  /*background-color: #666;
        height: 100%;*/ }

#generator_output_cms #alerts {
  width: 80%;
  /*height: 100%;
        background-color: #999;*/ }
  #generator_output_cms #alerts table thead {
    color: #00CC07; }
  #generator_output_cms #alerts table td {
    font-size: 14px; }

#scan_parameters {
  width: 800px; }
  #scan_parameters .w-55 {
    width: 55%; }
  #scan_parameters .w-45 {
    width: 45%; }
  #scan_parameters .col-form-label {
    font-weight: bold; }
  #scan_parameters .form-control {
    max-width: 100px; }
    #scan_parameters .form-control.datetimepicker-input {
      max-width: 150px; }

#comm_errors {
  height: calc(100vh - 40px);
  padding: 30px 10vw; }
  #comm_errors h5 {
    font-family: 'Titillium Web', sans-serif;
    letter-spacing: 1px; }
  #comm_errors #DataTables_Table_0_filter label {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600; }
    #comm_errors #DataTables_Table_0_filter label input {
      margin-left: 10px; }
  #comm_errors table {
    font-weight: bold; }
    #comm_errors table thead td {
      color: rgba(0, 204, 7, 0.6);
      border-bottom: 1px solid #dee2e6; }
      #comm_errors table thead td.sorting_asc, #comm_errors table thead td.sorting_desc {
        color: #00cc07; }
      #comm_errors table thead td:focus {
        outline: none; }
    #comm_errors table tbody tr {
      opacity: .8; }
      #comm_errors table tbody tr:hover {
        opacity: 1; }
      #comm_errors table tbody tr td {
        color: #7cbbc6;
        font-size: 14px;
        border-top: none;
        border-bottom: 1px solid rgba(222, 226, 230, 0.5); }
  #comm_errors .pagination .paginate_button .page-link {
    background-color: #7d7d7d;
    color: white;
    border: 1px solid rgba(50, 50, 50, 0.5);
    padding: .4rem .6rem; }
    #comm_errors .pagination .paginate_button .page-link:focus {
      box-shadow: 0 0 0 0.2rem rgba(50, 50, 50, 0.25); }
  #comm_errors .pagination .paginate_button.active {
    outline-color: black; }
    #comm_errors .pagination .paginate_button.active .page-link {
      background-color: #4b4b4b; }

[data-module="dashboard-counters"] {
  flex: 0 1 auto; }
  [data-module="dashboard-counters"] .card {
    width: 10rem;
    height: 8rem;
    font-size: 18px;
    font-weight: bold;
    color: white;
    text-align: center;
    font-family: sans-serif; }
    [data-module="dashboard-counters"] .card p {
      margin-bottom: 0; }
    [data-module="dashboard-counters"] .card.card-blue {
      background-color: #2121FF; }
    [data-module="dashboard-counters"] .card.card-yellow {
      background: url("/Content/img/StatusBox.jpg") repeat-x 9rem;
      color: black; }
      [data-module="dashboard-counters"] .card.card-yellow {
        background-color: transparent; }
    [data-module="dashboard-counters"] .card.card-link {
      cursor: pointer; }
      [data-module="dashboard-counters"] .card.card-link:hover {
        opacity: .9; }
    [data-module="dashboard-counters"] .card .card-header,
    [data-module="dashboard-counters"] .card .card-footer {
      height: 55%;
      padding: 0.5rem; }
    [data-module="dashboard-counters"] .card .card-body {
      padding: 0.5rem; }
      [data-module="dashboard-counters"] .card .card-body a {
        text-decoration: none;
        color: white; }
        [data-module="dashboard-counters"] .card .card-body a.text-black {
          color: black; }
    [data-module="dashboard-counters"] .card .card-text {
      font-size: 30px;
      height: 45%; }

[data-module="dashboard-events"] {
  flex: 1 1 auto; }
  [data-module="dashboard-events"] .chart-wrapper {
    background-color: #222;
    padding: 10px 10px 0 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 100%; }
    [data-module="dashboard-events"] .chart-wrapper > div {
      background-color: black;
      border-radius: 10px 10px 0 0; }

#event_charts .modal-dialog {
  width: 90vw;
  max-width: unset;
  height: auto; }
  #event_charts .modal-dialog .modal-content {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: #222;
    border: 1px solid grey; }
    #event_charts .modal-dialog .modal-content .modal-header {
      padding-top: 10px;
      padding-bottom: 5px;
      border-bottom: none; }
      #event_charts .modal-dialog .modal-content .modal-header h6 {
        border-bottom: none;
        color: white;
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 2px; }
        #event_charts .modal-dialog .modal-content .modal-header h6 strong {
          margin-left: 15px;
          margin-right: 5px;
          color: gray; }
          #event_charts .modal-dialog .modal-content .modal-header h6 strong:first-child {
            margin-left: 0; }
      #event_charts .modal-dialog .modal-content .modal-header div {
        float: right; }
        #event_charts .modal-dialog .modal-content .modal-header div button {
          margin-top: 0;
          margin-left: 0;
          margin-bottom: 0;
          padding-top: 2px;
          padding-bottom: 2px;
          margin-right: 10px; }
      #event_charts .modal-dialog .modal-content .modal-header .fa-window-close {
        float: right;
        font-size: 21px;
        font-weight: bold;
        line-height: 1;
        color: lightgray;
        text-shadow: 0 1px 0 #fff;
        filter: alpha(opacity=20);
        opacity: .2; }
        #event_charts .modal-dialog .modal-content .modal-header .fa-window-close:hover, #event_charts .modal-dialog .modal-content .modal-header .fa-window-close:focus {
          color: lightgray;
          text-decoration: none;
          cursor: pointer;
          filter: alpha(opacity=50);
          opacity: .5; }
    #event_charts .modal-dialog .modal-content .modal-body {
      background-color: #222;
      overflow-y: hidden;
      position: relative;
      width: 100%;
      padding: 0 20px;
      height: calc(100vh - 100px);
      padding-top: 5px;
      overflow-y: auto; }
      #event_charts .modal-dialog .modal-content .modal-body .chart {
        width: 88vw;
        margin-left: auto;
        margin-right: auto;
        height: calc( 100vh * 0.40);
        margin-bottom: 15px; }
        #event_charts .modal-dialog .modal-content .modal-body .chart > div {
          border-radius: 6px; }
      #event_charts .modal-dialog .modal-content .modal-body .loading {
        background-color: #222; }
    #event_charts .modal-dialog .modal-content .modal-footer {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: #222;
      border-top: none; }
      #event_charts .modal-dialog .modal-content .modal-footer div {
        display: inline-block;
        color: white;
        font-size: 14px; }

#event_charts ::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background-color: #000000; }

#event_charts ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #FFFFFF; }

#event_history {
  height: 100%; }
  #event_history .carousel-indicators {
    top: 0;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 0;
    height: 35px;
    z-index: 100; }
    #event_history .carousel-indicators li {
      text-indent: unset;
      flex: 1 1 auto;
      max-width: 20%;
      background-color: black;
      text-align: center; }
      #event_history .carousel-indicators li span {
        width: 100%;
        color: #00cc07;
        font-family: "Segoe UI","Helvetica Neue",Arial,"Noto Sans",sans-serif;
        font-size: 16px;
        font-weight: 700; }
  #event_history .carousel-inner {
    max-height: 100%;
    padding-top: 45px; }
    #event_history .carousel-inner .carousel-item {
      width: 100%;
      height: 100%; }
      #event_history .carousel-inner .carousel-item .history-chart {
        height: 100%; }

#dashboard .dataTables_wrapper .dataTables_scrollHeadInner table {
  margin-bottom: 0; }
  #dashboard .dataTables_wrapper .dataTables_scrollHeadInner table th {
    border-top: none;
    border-bottom: 2px solid rgba(222, 226, 230, 0.3);
    cursor: pointer;
    color: rgba(0, 204, 7, 0.6);
    font-size: 16px; }
    #dashboard .dataTables_wrapper .dataTables_scrollHeadInner table th.sorting_asc, #dashboard .dataTables_wrapper .dataTables_scrollHeadInner table th.sorting_desc {
      color: #00cc07; }
    #dashboard .dataTables_wrapper .dataTables_scrollHeadInner table th:focus {
      outline: none; }

#dashboard .dataTables_wrapper .dataTables_scrollBody::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  background-color: #000000; }

#dashboard .dataTables_wrapper .dataTables_scrollBody::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #FFFFFF; }

#dashboard .dataTables_wrapper #event_list {
  color: rgba(255, 255, 255, 0.8); }
  #dashboard .dataTables_wrapper #event_list td,
  #dashboard .dataTables_wrapper #event_list th {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 14px; }
  #dashboard .dataTables_wrapper #event_list tbody tr {
    opacity: .8; }
    #dashboard .dataTables_wrapper #event_list tbody tr:first-child {
      border-top: none; }
    #dashboard .dataTables_wrapper #event_list tbody tr:not(.event-charts):hover {
      cursor: pointer;
      opacity: 1; }
    #dashboard .dataTables_wrapper #event_list tbody tr:not(.event-charts) td {
      border-top: 1px solid rgba(222, 226, 230, 0.3); }
    #dashboard .dataTables_wrapper #event_list tbody tr.event-charts td {
      border-top: none;
      color: rgba(255, 255, 255, 0.7); }
      #dashboard .dataTables_wrapper #event_list tbody tr.event-charts td span {
        cursor: pointer; }
        #dashboard .dataTables_wrapper #event_list tbody tr.event-charts td span[disabled] {
          color: rgba(255, 255, 255, 0.2);
          cursor: not-allowed; }
          #dashboard .dataTables_wrapper #event_list tbody tr.event-charts td span[disabled]:hover {
            color: rgba(255, 255, 255, 0.2); }
        #dashboard .dataTables_wrapper #event_list tbody tr.event-charts td span:hover {
          color: white; }
    #dashboard .dataTables_wrapper #event_list tbody tr td {
      color: #7CBBC6;
      font-weight: bold; }
      #dashboard .dataTables_wrapper #event_list tbody tr td a {
        color: #7CBBC6;
        text-decoration: none;
        cursor: pointer; }
        #dashboard .dataTables_wrapper #event_list tbody tr td a:hover {
          color: #1ea6e0; }
        #dashboard .dataTables_wrapper #event_list tbody tr td a:visited {
          color: #7CBBC6;
          text-decoration: none; }

#real_time_data_viewer {
  background-color: black;
  padding: 0;
  display: flex; }
  #real_time_data_viewer #device_selector {
    height: calc(100vh - 40px);
    width: 20%;
    border-right: 1px solid rgba(200, 200, 200, 0.1);
    padding-top: 40px; }
    #real_time_data_viewer #device_selector #location_list h5,
    #real_time_data_viewer #device_selector #location_list h6 {
      cursor: pointer;
      color: #818181; }
      #real_time_data_viewer #device_selector #location_list h5.active,
      #real_time_data_viewer #device_selector #location_list h6.active {
        color: oldlace; }
      #real_time_data_viewer #device_selector #location_list h5:hover,
      #real_time_data_viewer #device_selector #location_list h6:hover {
        color: oldlace; }
    #real_time_data_viewer #device_selector #location_list .list-group-item {
      background-color: transparent; }
  #real_time_data_viewer #data-viewer {
    position: relative;
    width: 100%;
    padding-top: 60px; }
    #real_time_data_viewer #data-viewer #control_panel {
      z-index: 10;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: black;
      display: flex;
      justify-content: space-between;
      padding: 15px 25px 0 15px; }
      #real_time_data_viewer #data-viewer #control_panel #toolbar {
        width: 50%; }
        #real_time_data_viewer #data-viewer #control_panel #toolbar button {
          background-color: #333; }
      #real_time_data_viewer #data-viewer #control_panel #date_picker {
        width: 50%; }
        #real_time_data_viewer #data-viewer #control_panel #date_picker .col-form-label {
          color: white;
          font-weight: bold;
          text-align: right;
          padding-right: 5px; }
        #real_time_data_viewer #data-viewer #control_panel #date_picker .form-group {
          margin-bottom: 0; }
    #real_time_data_viewer #data-viewer #charts {
      position: relative;
      height: calc(100vh - 104px);
      overflow-y: auto;
      /*width: 80%;*/ }
      #real_time_data_viewer #data-viewer #charts #message {
        color: #818181;
        font-size: 20px;
        text-align: center;
        margin-top: 80px; }
        #real_time_data_viewer #data-viewer #charts #message.error-message {
          color: #721c24; }

[data-modal-dialog="event-status"] {
  width: 200px; }
  [data-modal-dialog="event-status"] .custom-radio {
    width: 100px; }
    [data-modal-dialog="event-status"] .custom-radio input {
      margin-right: 10px;
      cursor: pointer; }
      [data-modal-dialog="event-status"] .custom-radio input:checked + label {
        color: white;
        font-weight: 500; }
    [data-modal-dialog="event-status"] .custom-radio label {
      cursor: pointer;
      color: #555;
      letter-spacing: 1px; }

[data-modal-dialog="file-archive"] {
  width: 580px;
  margin-left: -25px;
  margin-right: -25px;
  font-weight: 500; }
  [data-modal-dialog="file-archive"] [file-name] {
    font-weight: bold; }

[data-modal-dialog="file-email"] {
  width: 500px; }
  [data-modal-dialog="file-email"].sending button[type=button] {
    display: inline-block; }
  [data-modal-dialog="file-email"].sending button[type=submit], [data-modal-dialog="file-email"].sending button[type=reset] {
    display: none; }
  [data-modal-dialog="file-email"].sent-success form {
    display: none; }
  [data-modal-dialog="file-email"].sent-success .result {
    display: block; }
    [data-modal-dialog="file-email"].sent-success .result .success {
      color: green;
      display: flex; }
  [data-modal-dialog="file-email"].sent-failure form {
    display: none; }
  [data-modal-dialog="file-email"].sent-failure .result {
    display: block; }
    [data-modal-dialog="file-email"].sent-failure .result .failure {
      color: red;
      display: flex; }
  [data-modal-dialog="file-email"] form button[type=button] {
    display: none; }
  [data-modal-dialog="file-email"] .result {
    display: none;
    padding: 60px 30px; }
    [data-modal-dialog="file-email"] .result > div {
      display: none;
      flex-flow: column;
      align-items: center; }
      [data-modal-dialog="file-email"] .result > div .fa-check-circle,
      [data-modal-dialog="file-email"] .result > div .fa-times-circle {
        font-size: 60px;
        margin-bottom: 20px; }

[data-modal-dialog="filters"] {
  width: 500px; }
  [data-modal-dialog="filters"] .switch {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
    margin-bottom: 0; }
    [data-modal-dialog="filters"] .switch input {
      opacity: 0;
      width: 0;
      height: 0; }
      [data-modal-dialog="filters"] .switch input:checked + .slider {
        background-color: #2196F3; }
        [data-modal-dialog="filters"] .switch input:checked + .slider:before {
          transform: translateX(24px); }
      [data-modal-dialog="filters"] .switch input:focus + .slider {
        box-shadow: 0 0 1px #2196F3; }
    [data-modal-dialog="filters"] .switch .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      transition: .4s; }
      [data-modal-dialog="filters"] .switch .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        transition: .4s; }
      [data-modal-dialog="filters"] .switch .slider.round {
        border-radius: 24px; }
        [data-modal-dialog="filters"] .switch .slider.round:before {
          border-radius: 50%; }
  [data-modal-dialog="filters"] fieldset {
    border: 1px solid #808080;
    border-radius: 5px;
    padding: 5px 10px; }
    [data-modal-dialog="filters"] fieldset legend {
      font-size: 1rem;
      font-weight: 500;
      width: auto;
      padding: 0 10px; }
    [data-modal-dialog="filters"] fieldset label[for] {
      cursor: pointer;
      font-weight: 500; }

[data-modal-dialog="file-uploader"] {
  width: 30%; }
  [data-modal-dialog="file-uploader"] h6 {
    font-weight: 700; }
  [data-modal-dialog="file-uploader"] [disabled] {
    display: none; }
  [data-modal-dialog="file-uploader"].uploading [data-button="upload-confirm"],
  [data-modal-dialog="file-uploader"].uploading [data-button="upload-cancel"] {
    display: none; }
  [data-modal-dialog="file-uploader"].uploading [disabled] {
    display: block; }

[data-modal-dialog="file-viewer"] {
  width: 98vw; }
  [data-modal-dialog="file-viewer"].initialized .loading {
    display: none; }
  [data-modal-dialog="file-viewer"] h6 {
    color: white; }
    [data-modal-dialog="file-viewer"] h6 span {
      font-weight: bold;
      padding: 0 10px;
      color: grey; }
      [data-modal-dialog="file-viewer"] h6 span:first-child {
        padding-right: 10px; }
  [data-modal-dialog="file-viewer"] .mfp-close {
    font-size: 3rem; }
  [data-modal-dialog="file-viewer"] .custom-body {
    padding-right: 20px;
    height: 88vh;
    overflow-y: auto; }
    [data-modal-dialog="file-viewer"] .custom-body .chart {
      height: 44vh;
      padding: 1vh 0; }
      [data-modal-dialog="file-viewer"] .custom-body .chart > div {
        border-radius: 7px; }
  [data-modal-dialog="file-viewer"] ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    background-color: #000000; }
  [data-modal-dialog="file-viewer"] ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #FFFFFF; }

.mfp-zoom-in {
  /* start state */
  /* animate in */
  /* animate out */ }
  .mfp-zoom-in .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transform: scale(0.8); }
  .mfp-zoom-in.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease-out; }
  .mfp-zoom-in.mfp-ready .mfp-with-anim {
    opacity: 1;
    transform: scale(1); }
  .mfp-zoom-in.mfp-ready.mfp-bg {
    opacity: 0.9;
    background-color: #000; }
  .mfp-zoom-in.mfp-removing .mfp-with-anim {
    transform: scale(0.8);
    opacity: 0; }
  .mfp-zoom-in.mfp-removing.mfp-bg {
    opacity: 0; }

.mfp-wrap .mfp-content > div {
  position: relative;
  padding: 25px;
  margin: 0 auto;
  border-radius: 5px; }
  .mfp-wrap .mfp-content > div.black-popup {
    background-color: #000;
    border: 1px solid rgba(125, 125, 125, 0.5); }
  .mfp-wrap .mfp-content > div.white-popup {
    background-color: whitesmoke; }
  .mfp-wrap .mfp-content > div[data-modal-dialog="location-editor"] {
    max-width: 500px; }
  .mfp-wrap .mfp-content > div button:focus {
    box-shadow: none; }
  .mfp-wrap .mfp-content > div form {
    margin-top: 35px; }

#data_files {
  height: calc(100vh - 40px);
  padding: 50px 5% 30px; }
  #data_files > div {
    flex-grow: 1; }
    #data_files > div .config-toolbar {
      display: flex;
      justify-content: space-between; }
      #data_files > div .config-toolbar button {
        background-color: #333;
        border: 1px solid rgba(99, 99, 99, 0.5);
        min-width: 46px;
        margin-bottom: 3px; }
        #data_files > div .config-toolbar button:hover {
          background-color: #666; }
      #data_files > div .config-toolbar #DataTables_Table_0_filter label {
        color: rgba(255, 255, 255, 0.5);
        font-weight: 600; }
        #data_files > div .config-toolbar #DataTables_Table_0_filter label input {
          margin-left: 10px; }
    #data_files > div table {
      font-weight: bold; }
      #data_files > div table thead td {
        color: rgba(0, 204, 7, 0.6);
        border-bottom: 1px solid #dee2e6; }
        #data_files > div table thead td.sorting_asc, #data_files > div table thead td.sorting_desc {
          color: #00cc07; }
        #data_files > div table thead td:focus {
          outline: none; }
      #data_files > div table tbody tr {
        opacity: .8; }
        #data_files > div table tbody tr:hover {
          opacity: 1; }
          #data_files > div table tbody tr:hover .file-controls {
            display: block; }
        #data_files > div table tbody tr td {
          color: #7cbbc6;
          font-size: 14px;
          border-top: none;
          border-bottom: 1px solid rgba(222, 226, 230, 0.5); }
          #data_files > div table tbody tr td:not(.status):hover, #data_files > div table tbody tr td:not(.tools):hover {
            cursor: pointer; }
          #data_files > div table tbody tr td .file-controls {
            display: none; }
            #data_files > div table tbody tr td .file-controls .svg-inline--fa:hover {
              color: white; }
          #data_files > div table tbody tr td:nth-last-child(2) {
            color: white; }
          #data_files > div table tbody tr td.tools {
            min-width: 70px; }
  #data_files .dts_label {
    display: none; }
  #data_files .dataTables_paginate {
    margin-top: 10px; }
    #data_files .dataTables_paginate .pagination .paginate_button .page-link {
      background-color: #7d7d7d;
      color: white;
      border: 1px solid rgba(50, 50, 50, 0.5);
      padding: .4rem .6rem; }
      #data_files .dataTables_paginate .pagination .paginate_button .page-link:focus {
        box-shadow: 0 0 0 0.2rem rgba(50, 50, 50, 0.25); }
    #data_files .dataTables_paginate .pagination .paginate_button.active {
      outline-color: black; }
      #data_files .dataTables_paginate .pagination .paginate_button.active .page-link {
        background-color: #4b4b4b; }

[name='login-form'] {
  background-color: #666;
  width: 400px;
  padding: 0 30px;
  margin-top: 30px; }
  [name='login-form'] .error-message {
    background-color: #f00;
    color: white;
    padding: 3px 5px 0;
    margin-bottom: -25px;
    margin-top: 10px;
    border-radius: .25rem; }
  [name='login-form'] .input-group.focus-in {
    box-shadow: 0 0 0 0.1rem rgba(0, 0, 0, 0.25);
    border-radius: 4px; }
  [name='login-form'] input {
    background-color: #ccc;
    border: 1px solid #999; }
    [name='login-form'] input:focus {
      background-color: #ddd;
      border: 1px solid #666;
      box-shadow: none; }
  [name='login-form'] button {
    font-weight: 500;
    letter-spacing: 1px; }

.site-settings {
  background-color: black;
  padding-top: 100px;
  padding-bottom: 20px; }
  .site-settings h6 {
    color: rgba(255, 255, 255, 0.5); }
  .site-settings .card {
    border: 0.5px solid rgba(125, 125, 125, 0.5); }
    .site-settings .card ul {
      border: 0.5px solid rgba(125, 125, 125, 0.5);
      list-style-type: none; }
      .site-settings .card ul .list-group-item {
        margin-bottom: 0; }
      .site-settings .card ul li.success form {
        display: none; }
      .site-settings .card ul li.success .saved {
        display: flex;
        align-items: center;
        justify-content: center; }
      .site-settings .card ul form {
        background-color: grey; }
        .site-settings .card ul form label {
          font-weight: 600;
          font-size: 1rem; }
        .site-settings .card ul form input {
          opacity: .8; }
          .site-settings .card ul form input:focus {
            box-shadow: 0 0 0 0.2rem rgba(200, 200, 200, 0.25);
            border-color: #c8c8c8; }
        .site-settings .card ul form button {
          font-weight: 600; }
      .site-settings .card ul .saved {
        height: 160px;
        background-color: #808080;
        display: none; }
        .site-settings .card ul .saved svg {
          color: white; }
  .site-settings .custom-control-label {
    cursor: pointer; }
  .site-settings .custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: 0 0 0 0.2rem rgba(125, 125, 125, 0.25); }
  .site-settings .custom-control-input:checked ~ .custom-control-label:before {
    border-color: grey;
    background-color: grey; }

.control-panel {
  height: 100%;
  width: 250px;
  padding-top: 25px;
  background-color: #333; }
  .control-panel ul {
    list-style-type: none;
    padding-left: 0; }
    .control-panel ul.super-admin li {
      display: none; }
      .control-panel ul.super-admin li:last-of-type {
        display: block; }
    .control-panel ul li {
      margin-bottom: 15px; }
      .control-panel ul li .panel-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 15px;
        color: rgba(255, 255, 255, 0.4);
        font-size: 14px;
        letter-spacing: 1px; }
        .control-panel ul li .panel-link .fa-angle-down {
          display: none; }
        .control-panel ul li .panel-link[aria-expanded="true"] .fa-angle-right {
          display: none; }
        .control-panel ul li .panel-link[aria-expanded="true"] .fa-angle-down {
          display: initial; }
        .control-panel ul li .panel-link:hover {
          color: white;
          background-color: #666;
          text-decoration: none; }
      .control-panel ul li > .panel-link.activated {
        color: white;
        background-color: #326EB0; }
      .control-panel ul li div {
        background-color: #222;
        padding: 5px 0; }
        .control-panel ul li div.collapsing.activating {
          transition: none; }
        .control-panel ul li div .panel-link:hover {
          background-color: #333; }
        .control-panel ul li div .panel-link.activated {
          color: white; }
        .control-panel ul li div .panel-link.disabled {
          color: rgba(96, 96, 96, 0.5);
          cursor: not-allowed; }

@font-face {
  font-family: 'Titillium Web';
  src: url(./fonts/TitilliumWeb-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Titillium Web';
  src: url(./fonts/TitilliumWeb-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Titillium Web';
  src: url(./fonts/TitilliumWeb-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal; }

header {
  height: 40px;
  color: white;
  background-color: #333333;
  border-bottom: 1px solid rgba(125, 125, 125, 0.5); }
  header .fb-logo {
    width: 200px; }
  header span {
    letter-spacing: 0.5px; }
    header span[name="app-header"] {
      font-size: 18px;
      letter-spacing: 1.5px; }
  header label {
    cursor: pointer;
    color: #606060; }
    header label.active,
    header label :hover {
      color: white; }

html {
  height: 100%; }
  html body {
    font-family: 'Titillium Web', sans-serif;
    height: 100%; }
    html body .body {
      position: relative;
      height: calc(100vh - 40px); }
      html body .body .page-wrapper {
        flex: 1 1 100%;
        padding: 30px 30px 0;
        background-color: black;
        overflow-y: auto; }
        html body .body .page-wrapper-admin {
          background-color: #eee;
          padding: 50px 40px; }
  html .error {
    color: rgba(255, 0, 0, 0.7);
    margin-top: 0.25rem;
    font-size: 0.875rem; }
  html .spinner-border {
    vertical-align: middle; }
  html a {
    text-decoration: none !important; }

#status_panel {
  z-index: 9999;
  position: fixed;
  right: 0; }
  #status_panel > div {
    display: none;
    position: relative;
    padding: 15px 15px 16px 35px;
    width: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    min-height: 80px;
    color: white;
    cursor: pointer;
    flex-direction: column;
    transition: width 2s;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.2); }
    #status_panel > div.show {
      display: flex;
      width: 100%;
      max-width: 25vw;
      min-width: 300px; }
    #status_panel > div h6 {
      font-weight: 700;
      letter-spacing: 0.5px; }
    #status_panel > div p {
      margin-bottom: 0; }
      #status_panel > div p span {
        font-weight: 900; }
    #status_panel > div .status-icon {
      position: absolute;
      left: -25px;
      height: 50px;
      width: 50px;
      padding: 5px;
      border-radius: 50%;
      font-size: 28px;
      text-align: center;
      box-shadow: inset 0 0 0 6px rgba(255, 255, 255, 0.5); }
    #status_panel > div .status-progress {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 4px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      border-bottom-left-radius: 2px; }
  #status_panel .status-success {
    background-color: #66BB6A; }
    #status_panel .status-success .status-icon {
      background-color: #66BB6A; }
  #status_panel .status-failure {
    background-color: #BB666A; }
    #status_panel .status-failure .status-icon {
      background-color: #BB666A; }
  #status_panel:hover {
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 12px 0 rgba(0, 0, 0, 0.2); }

.loading {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black; }
  .loading img {
    width: 80px;
    height: 80px; }
  .loading.loading-sm img {
    width: 50px;
    height: 50px; }

.tooltip[x-placement="top"] .arrow:before {
  border-top-color: #333; }

.tooltip .tooltip-inner {
  background-color: #333; }

button {
  letter-spacing: 1px; }
  button:focus {
    box-shadow: none !important; }

.w-15 {
  width: 15% !important; }

.w-35 {
  width: 35% !important; }

.div-select {
  position: relative; }
  .div-select select {
    display: none; }
  .div-select .select-selected {
    border-radius: 5px;
    border: 1px solid #333;
    color: #ffffff;
    padding: 8px 16px;
    cursor: pointer; }
    .div-select .select-selected.select-arrow-active {
      border-radius: 5px 5px 0 0;
      border-bottom: none; }
      .div-select .select-selected.select-arrow-active:after {
        border-color: transparent transparent #fff transparent;
        top: 10px; }
    .div-select .select-selected:after {
      position: absolute;
      content: "";
      top: 20px;
      right: 10px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-color: #fff transparent transparent transparent; }
  .div-select .select-items {
    border-radius: 0 0 5px 5px;
    border: 1px solid #333;
    border-top: none;
    position: absolute;
    background-color: #0A0A0A;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99; }
    .div-select .select-items div {
      color: #aaa;
      padding: 8px 16px;
      border: 1px solid transparent;
      border-color: transparent transparent rgba(255, 255, 255, 0.1) transparent;
      cursor: pointer; }
      .div-select .select-items div:hover {
        background-color: black; }
      .div-select .select-items div.same-as-selected {
        color: #fff;
        font-weight: bold; }
  .div-select .select-hide {
    display: none; }

.cmg-loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 33px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em; }
  .cmg-loader:before {
    background: #ffffff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
    position: absolute;
    top: 0;
    content: '';
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s; }
  .cmg-loader:after {
    background: #ffffff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
    position: absolute;
    top: 0;
    content: '';
    left: 1.5em; }

@-webkit-keyframes load1 {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

@keyframes load1 {
  0%, 80%, 100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

#device_activity {
  height: calc(100vh - 40px);
  padding: 50px 10vw; }
  #device_activity h5 {
    font-family: 'Titillium Web', sans-serif;
    letter-spacing: 1px; }
  #device_activity #DataTables_Table_0_filter label {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600; }
    #device_activity #DataTables_Table_0_filter label input {
      margin-left: 10px; }
  #device_activity table {
    font-weight: bold; }
    #device_activity table thead td {
      color: rgba(0, 204, 7, 0.6);
      border-bottom: 1px solid #dee2e6; }
      #device_activity table thead td.sorting_asc, #device_activity table thead td.sorting_desc {
        color: #00cc07; }
      #device_activity table thead td:focus {
        outline: none; }
    #device_activity table tbody tr {
      opacity: .8; }
      #device_activity table tbody tr:hover {
        opacity: 1; }
      #device_activity table tbody tr td {
        color: #7cbbc6;
        font-size: 14px;
        border-top: none;
        border-bottom: 1px solid rgba(222, 226, 230, 0.5); }
        #device_activity table tbody tr td[fbd-file-viewer]:hover {
          cursor: pointer; }
  #device_activity .pagination .paginate_button .page-link {
    background-color: #7d7d7d;
    color: white;
    border: 1px solid rgba(50, 50, 50, 0.5);
    padding: .4rem .6rem; }
    #device_activity .pagination .paginate_button .page-link:focus {
      box-shadow: 0 0 0 0.2rem rgba(50, 50, 50, 0.25); }
  #device_activity .pagination .paginate_button.active {
    outline-color: black; }
    #device_activity .pagination .paginate_button.active .page-link {
      background-color: #4b4b4b; }

#power_outage_tracker {
  height: calc(100vh - 40px);
  padding: 50px 10vw; }
  #power_outage_tracker h5 {
    font-family: 'Titillium Web', sans-serif;
    letter-spacing: 1px; }
  #power_outage_tracker #DataTables_Table_0_filter label {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 600; }
    #power_outage_tracker #DataTables_Table_0_filter label input {
      margin-left: 10px; }
  #power_outage_tracker table {
    font-weight: bold; }
    #power_outage_tracker table thead td {
      color: rgba(0, 204, 7, 0.6);
      border-bottom: 1px solid #dee2e6; }
      #power_outage_tracker table thead td.sorting_asc, #power_outage_tracker table thead td.sorting_desc {
        color: #00cc07; }
      #power_outage_tracker table thead td:focus {
        outline: none; }
    #power_outage_tracker table tbody tr {
      opacity: .8; }
      #power_outage_tracker table tbody tr:hover {
        opacity: 1; }
      #power_outage_tracker table tbody tr td {
        color: #7cbbc6;
        font-size: 14px;
        border-top: none;
        border-bottom: 1px solid rgba(222, 226, 230, 0.5); }
        #power_outage_tracker table tbody tr td[fbd-file-viewer]:hover {
          cursor: pointer; }
  #power_outage_tracker .pagination .paginate_button .page-link {
    background-color: #7d7d7d;
    color: white;
    border: 1px solid rgba(50, 50, 50, 0.5);
    padding: .4rem .6rem; }
    #power_outage_tracker .pagination .paginate_button .page-link:focus {
      box-shadow: 0 0 0 0.2rem rgba(50, 50, 50, 0.25); }
  #power_outage_tracker .pagination .paginate_button.active {
    outline-color: black; }
    #power_outage_tracker .pagination .paginate_button.active .page-link {
      background-color: #4b4b4b; }
